import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useTokenHolders } from "@/hooks";
import { TokenHolderRow } from "@/components/TokenHolderRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type TokenHoldersListProps = {
  contract: any;
  paginate?: boolean;
  compact?: boolean;
};

export function TokenHoldersList({
  contract,
  paginate,
  compact,
}: TokenHoldersListProps) {
  const {
    holders,
    total,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useTokenHolders({ contractAddress: contract.address });
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              {contract.op20Metadata?.symbol ?? "Token"} Balance
            </TableHead>
            <TableHead>Holder Address</TableHead>
            <TableHead>Percent of Total Supply</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 10 }).map((_, i) => (
                <SkeletonRow
                  icon={true}
                  title={true}
                  subtitle={true}
                  date={false}
                  otherCols={1}
                  key={i}
                />
              ))
            : null}
          {!loading &&
            holders.map((holder: any) => (
              <TokenHolderRow
                compact={compact}
                holder={holder}
                contract={contract}
                key={holder.address}
              />
            ))}
        </TableBody>
      </Table>
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            total={total}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      )}
    </div>
  );
}
