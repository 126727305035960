import { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { LucideIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { TableRow } from "@/components/ui/table";
import { formatDateToNow } from "@/lib";
import { Button } from "@/components/ui/button";

export type CommonRowProps = {
  icon: LucideIcon;
  title: ReactNode;
  subtitle?: ReactNode;
  date?: string | Date | number;
  className?: string;
  href?: string;
};

export function CommonRow({
  icon: Icon,
  title,
  subtitle,
  date,
  className,
  children,
  href,
}: PropsWithChildren<CommonRowProps>) {
  return (
    <Transition
      show={true}
      appear={true}
      as={TableRow}
      className={cn(["bg-white text-sm", className])}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <CenteredRowCell key="icon">
        <div className="p-2 bg-black rounded-lg inline-block">
          <Icon color="#FFF" className="h-5 w-5" />
        </div>
        <div className="ml-4 flex flex-col items-start justify-center">
          {href ? (
            <Button
              className="font-bold text-sm p-0 h-auto"
              variant="link"
              key="title"
              asChild
            >
              <Link to={href}>{title}</Link>
            </Button>
          ) : (
            <div className="font-bold text-sm p-0 h-auto" key="title">
              {title}
            </div>
          )}
          {subtitle && (
            <div
              className="text-xs font-medium text-muted-foreground"
              key="subtitle"
            >
              {subtitle}
            </div>
          )}
        </div>
      </CenteredRowCell>
      {children}
      {date && (
        <CenteredRowCell
          className="text-muted-foreground font-medium text-xs"
          key="date"
        >
          <div className="flex grow text-end justify-end whitespace-nowrap">
            {formatDateToNow(date)}
          </div>
        </CenteredRowCell>
      )}
    </Transition>
  );
}
