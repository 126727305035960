import { NavLink, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { Wallet2, Menu, Network } from "lucide-react";
import OpscanLogo from "../assets/opscan.png";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Search } from "@/components/Search";
import { NetworkState } from "@/state";
import { ConnectWalletButton } from "@/components/ConnectWalletButton";
import { WalletConnect } from "@/services";

function NavigationList() {
  return (
    <>
      <NavLink
        to="/blocks"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Blocks
      </NavLink>
      <NavLink
        to="/transactions"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Transactions
      </NavLink>
      <NavLink
        to="/tokens"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Tokens
      </NavLink>
    </>
  );
}

export function Header() {
  const [network, setNetwork] = useRecoilState(NetworkState);

  const online = true;
  const loading = false;

  useEffect(() => {
    try {
      WalletConnect.instance.switchNetwork(network);
    } catch (err) {}
  }, [network]);

  return (
    <div className="container flex items-center px-4 h-16 justify-between">
      <Link className="flex flex-row items-center gap-2 font-bold" to="/">
        <img src={OpscanLogo} className="h-8" alt="op_scan" />
      </Link>
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <NavigationList />
      </nav>
      <Search className="max-w-sm hidden md:flex md:w-96" />
      <Select value={network} onValueChange={setNetwork}>
        <SelectTrigger className="w-[150px]">
          <SelectValue placeholder="Network" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="regtest">
              <div className="flex flex-row items-center font-bold gap-1">
                {online && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FF7900] opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-[#FF7900]"></span>
                  </span>
                )}
                {!online && !loading && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-destructive opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-destructive"></span>
                  </span>
                )}
                BTC Regtest
              </div>
            </SelectItem>
            <SelectItem value="testnet">
              <div className="flex flex-row items-center font-bold gap-1">
                {online && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FF7900] opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-[#FF7900]"></span>
                  </span>
                )}
                {!online && !loading && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-destructive opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-destructive"></span>
                  </span>
                )}
                BTC Testnet
              </div>
            </SelectItem>
            <SelectItem value="fractal_testnet">
              {" "}
              <div className="flex flex-row items-center font-bold gap-1">
                {online && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FF7900] opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-[#FF7900]"></span>
                  </span>
                )}
                {!online && !loading && (
                  <span className="relative flex h-2 w-2 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-destructive opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-destructive"></span>
                  </span>
                )}
                Fractal Testnet
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <ConnectWalletButton />
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium py-10">
            <Search />
            <NavigationList />
            {/*<Button
              variant={connected ? "default" : "outline"}
              className="flex w-full md:hidden"
              onClick={connect}
            >
              <Wallet2 className="h-5 w-5 mr-2" />
              {connected ? "Connected" : "Connect Wallet"}
            </Button>*/}
          </nav>
        </SheetContent>
      </Sheet>
    </div>
  );
}
