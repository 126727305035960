import { createContext, PropsWithChildren } from "react";
import {
  TransactionsApi,
  BlocksApi,
  InfoApi,
  SearchApi,
  SmartContractsApi,
  AccountsApi,
  Configuration,
  NonFungibleTokensApi,
  NamesApi,
} from "@stacks/blockchain-api-client";
import {
  TokensApi,
  Configuration as TokenConfiguration,
} from "@hirosystems/token-metadata-api-client";

import { OpnetApiClient } from "../services";

export interface StacksApiContextValue {
  transactionsApi: TransactionsApi;
  blocksApi: BlocksApi;
  infoApi: InfoApi;
  searchApi: SearchApi;
  smartContractsApi: SmartContractsApi;
  accountsApi: AccountsApi;
  namesApi: NamesApi;
  opnetApi: OpnetApiClient;
}

export interface StacksApiProviderProps {
  config: Configuration;
  tokenConfig: TokenConfiguration;
}

export const StacksApiContext = createContext({
  transactionsApi: new TransactionsApi(),
  blocksApi: new BlocksApi(),
  infoApi: new InfoApi(),
  searchApi: new SearchApi(),
  smartContractsApi: new SmartContractsApi(),
  accountsApi: new AccountsApi(),
  tokensApi: new TokensApi(),
  nonFungibleTokensApi: new NonFungibleTokensApi(),
  namesApi: new NamesApi(),
  opnetApi: OpnetApiClient.instance,
});

export const StacksApiProvider = ({
  config,
  tokenConfig,
  children,
}: PropsWithChildren<StacksApiProviderProps>) => {
  const contextValue = {
    transactionsApi: new TransactionsApi(config),
    blocksApi: new BlocksApi(config),
    infoApi: new InfoApi(config),
    searchApi: new SearchApi(config),
    smartContractsApi: new SmartContractsApi(config),
    accountsApi: new AccountsApi(config),
    tokensApi: new TokensApi(tokenConfig),
    nonFungibleTokensApi: new NonFungibleTokensApi(config),
    namesApi: new NamesApi(config),
    opnetApi: OpnetApiClient.instance,
  };

  return (
    <StacksApiContext.Provider value={contextValue}>
      {children}
    </StacksApiContext.Provider>
  );
};
