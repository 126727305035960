import moment from "moment-timezone";

function formatUnit(unit: string, value: number) {
  return `${value} ${unit}${value > 1 ? "s" : ""}`;
}

export function formatDateToNow(dateRaw: Date | string | number) {
  const date = moment.tz(dateRaw, "UTC");
  const now = moment().utc();

  const diffMinutes = now.diff(date, "minutes");

  if (diffMinutes < 1) {
    return "Now";
  }

  const diffHours = now.diff(date, "hours");
  const diffDays = now.diff(date, "days");

  let formattedUnit = "";

  if (diffMinutes < 60) {
    formattedUnit = formatUnit("min", diffMinutes);
  } else if (diffHours < 24) {
    formattedUnit = formatUnit("hr", diffHours);
  } else if (diffDays < 30) {
    formattedUnit = formatUnit("day", diffDays);
  } else {
    return date.format("MM/DD/YY hh:mm A");
  }

  return `${formattedUnit} ago`;
}
