import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Blocks,
  Receipt,
  PersonStanding,
  Code,
  LucideIcon,
  Coins,
} from "lucide-react";

import { Button } from "./ui/button";
import { truncateInMiddle } from "@/lib/truncateInMiddle";

export type SearchItemProps = {
  entity: any;
};

export function SearchItem({ entity }: SearchItemProps) {
  const title = useMemo(() => {
    if (entity.type === "block") {
      return entity.block?.height
        ? `#${Number(entity.block.height).toLocaleString("en-US")}`
        : truncateInMiddle(entity?.block?.hash || "", 10);
    } else if (entity.type === "contract") {
      if (
        entity.contract?.isOP20 &&
        entity.contract?.op20Metadata?.name &&
        entity.contract?.op20Metadata?.symbol
      ) {
        return `${entity.contract?.op20Metadata?.name} ($${entity.contract?.op20Metadata?.symbol})`;
      }
      return truncateInMiddle(entity?.contract?.address || "", 10);
    } else if (entity.type === "wallet") {
      return truncateInMiddle(entity?.wallet || "", 10);
    } else {
      return truncateInMiddle(entity.transaction?.id || "", 10);
    }
  }, [entity]);
  const Icon: LucideIcon = useMemo(() => {
    if (entity.type === "block") {
      return Blocks;
    } else if (entity.type === "contract") {
      if (entity.contract?.isOP20) {
        return Coins;
      }
      return Code;
    } else if (entity.type === "wallet") {
      return PersonStanding;
    } else {
      return Receipt;
    }
  }, [entity]);
  const href = useMemo(() => {
    switch (entity.type) {
      case "block":
        return `/blocks/${entity.block?.height}`;
      case "contract":
        return `/accounts/${entity.contract?.address}`;
      case "wallet":
        return `/accounts/${entity.wallet}`;
      case "transaction":
      default:
        return `/transactions/${entity.transaction?.id}`;
    }
  }, [entity]);
  return (
    <div className="bg-white text-sm p-4 flex flex-row">
      <div className="p-2 bg-black rounded-lg inline-block">
        <Icon color="#FFF" className="h-5 w-5" />
      </div>
      <div className="ml-4 flex flex-col items-start justify-center">
        <Button
          className="font-bold text-sm p-0 h-auto text-primary"
          variant="link"
          key="title"
          asChild
        >
          <Link to={href}>{title}</Link>
        </Button>
      </div>
    </div>
  );
}
