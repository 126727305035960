import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface TokenHolderProps extends PaginationOptions {
  contractAddress: string;
}

export function useTokenHolders(props: TokenHolderProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    opnetApi.getTokenHoldersList.bind(opnetApi),
    [network, props.contractAddress, { limit: pageSize, cursor: page - 1 }]
  );

  return {
    holders: data?.results || [],
    total: data?.total ?? 0,
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
