import { Separator } from "@/components/ui/separator";

export function Footer() {
  return (
    <div className="container p-4 text-muted-foreground">
      <Separator />
      <div className="flex flex-col md:flex-row pt-4 font-medium justify-between items-center">
        <p>© 2024 OP_SCAN</p>{" "}
        <p className="text-sm">Price Data Powered by CoinGecko</p>
      </div>
    </div>
  );
}
