import React, { useState, useRef, useCallback, useEffect } from "react";
import { SearchIcon } from "lucide-react";
import { Combobox } from "@headlessui/react";

import { cn } from "@/lib/utils";
import { useSearch } from "@/hooks/useSearch";
import { SearchItem } from "@/components/SearchItem";

export function Search({ className }: { className?: string }) {
  const ref = useRef(null);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const { results } = useSearch({
    id: searchQuery,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const onChange = useCallback(() => {
    const value = (ref?.current as any)?.value as any;
    setQuery(value);
  }, []);

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative">
        <div
          className={cn(
            "flex items-center h-10 w-full overflow-hidden rounded-md border border-input bg-background text-sm ring-offset-background focus-within:shadow disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        >
          <SearchIcon className="mx-3 h-5 w-5" />
          <Combobox.Input
            className="flex grow bg-background py-2 pr-3 focus-visible:outline-none placeholder:text-muted-foreground"
            placeholder="Search by Address / Tx Hash / Block"
            onChange={onChange}
            onPaste={onChange}
            onCut={onChange}
            displayValue={(framework: any) => framework?.label}
            ref={ref}
          />
        </div>
        {query !== "" && results?.length > 0 && (
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-hidden">
            {results.map((res: any, i: number) => (
              <Combobox.Option
                key={i}
                value={res.type}
                className={({ active }: any) =>
                  cn("relative cursor-default select-none")
                }
              >
                <SearchItem entity={res} />
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
