import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface AccountBalancesProps extends PaginationOptions {
  address: string;
  untilBlock?: string;
}

export function useAccountBalances(props: AccountBalancesProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    opnetApi.getWalletBalances.bind(opnetApi),
    [
      network,
      props.address,
      {
        limit: pageSize,
        cursor: page - 1,
      },
    ]
  );

  return {
    balances: data?.results || [],
    total: data?.total ?? 0,
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
