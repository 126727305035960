import { Blocks } from "lucide-react";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import {
  truncateInMiddle,
  formatCountPlural,
  formatNumber,
  satsToBTC,
  gasToSats,
} from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";

export type BlockRowProps = {
  block: any;
  compact?: boolean;
  className?: string;
};

export function BlockRow({ block, compact = false, className }: BlockRowProps) {
  const blockHeight = Number(block.height).toLocaleString("en-US");
  const burnedBitcoin = satsToBTC(BigInt(block.burnedBitcoin));
  const gasUsedBTC = satsToBTC(gasToSats(BigInt(block.gasUsed)));
  return (
    <CommonRow
      icon={Blocks}
      title={`#${blockHeight}`}
      date={block.time}
      className={className}
      href={`/blocks/${block?.height}`}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={block.hash}
            displayText={truncateInMiddle(block.hash, 10)}
          />
        </Badge>
      </CenteredRowCell>
      <CenteredRowCell className="hidden lg:table-cell">
        <Badge className="font-medium">
          {formatCountPlural(block.txCount ?? 0, "Transaction", "Transactions")}
        </Badge>
      </CenteredRowCell>
      {!compact && (
        <>
          <CenteredRowCell className="hidden lg:table-cell">
            <div className="text-muted-foreground text-sm">
              {formatNumber(Number(burnedBitcoin))} BTC
            </div>
          </CenteredRowCell>
          <CenteredRowCell className="hidden lg:table-cell">
            <div className="text-muted-foreground text-sm">
              {formatNumber(Number(gasUsedBTC))} BTC
            </div>
          </CenteredRowCell>
        </>
      )}
    </CommonRow>
  );
}
