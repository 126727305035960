import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export enum TransactionType {
  coinbase = "coinbase",
  token_transfer = "token_transfer",
  smart_contract = "smart_contract",
  contract_call = "contract_call",
  poison_microblock = "poison_microblock",
  tenure_change = "tenure_change",
}

export interface UseTransactionListProps extends PaginationOptions {
  types?: TransactionType[];
  blockHash?: string;
}

export function useTransactions(props?: UseTransactionListProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const apiFn = props?.blockHash
    ? opnetApi.getBlockTransactions
    : opnetApi.getTransactionList;

  const args: any[] = [network];

  if (typeof props?.blockHash !== "undefined") {
    args.push(props.blockHash);
  }

  args.push({
    limit: pageSize,
    cursor: page - 1,
  });

  const { data, loading, error } = useApi(apiFn.bind(opnetApi), args);

  return {
    transactions: data?.results || [],
    total: data?.total ?? 0,
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
