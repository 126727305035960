import {
  JSONRpcProvider,
  BitcoinInterface,
  BitcoinInterfaceAbi,
  BitcoinAbiValue,
  ABIDataTypes,
  ContractDecodedObjectResult,
  DecodedOutput,
  FunctionBaseData,
  BitcoinAddressLike,
  DecodedCallResult,
  getContract,
  IOP_20Contract,
  OP_20_ABI,
} from "opnet";
import {
  OPNetLimitedProvider,
  TransactionFactory,
  UnisatSigner,
  AddressVerificator,
  UnisatNetwork,
  BroadcastedTransaction,
  UTXO,
  IInteractionParameters,
  InteractionParametersWithoutSigner,
  FetchUTXOParamsMultiAddress,
  Unisat,
  OPNetNetwork,
} from "@btc-vision/transaction";
import {
  ABICoder,
  BinaryWriter,
  BinaryReader,
  Address,
} from "@btc-vision/bsi-binary";
import { setRecoil } from "recoil-nexus";
import { BitcoinNetwork } from "opscan-core";

import { NetworkState, WalletConnectState } from "../state";

const Buffer = require("buffer/").Buffer;

export class WalletSigner extends UnisatSigner {
  private wallet?: Unisat;

  public get unisat(): Unisat {
    const module = this.wallet;
    if (!module) {
      throw new Error("OPWallet extension not found");
    }
    return module;
  }

  public initWithWallet(wallet: string): Promise<void> {
    console.log(wallet);
    if (wallet === "opwallet") {
      this.wallet = window.opnet;
    } else if (wallet === "unisat") {
      this.wallet = window.unisat;
    } else {
      throw new Error("Wallet not supported");
    }
    return this.init();
  }
}

export class WalletConnect {
  public static instance: WalletConnect = new WalletConnect();

  private connected: boolean = false;
  private wallet?: string;

  private provider?: JSONRpcProvider;
  private utxoManager?: OPNetLimitedProvider;
  private signer?: WalletSigner;
  private readonly transactionFactory: TransactionFactory;
  private readonly abiCoder: ABICoder;

  private constructor() {
    this.transactionFactory = new TransactionFactory();
    this.abiCoder = new ABICoder();

    window.unisat?.on(
      "accountsChanged",
      this.onAccountsChanged.bind(this, "unisat")
    );
    window.unisat?.on(
      "networkChanged",
      this.onNetworkChanged.bind(this, "unisat")
    );

    window.opnet?.on(
      "accountsChanged",
      this.onAccountsChanged.bind(this, "opwallet")
    );
    window.opnet?.on(
      "networkChanged",
      this.onNetworkChanged.bind(this, "opwallet")
    );
  }

  private onAccountsChanged(wallet: string, accounts: Array<string>): void {
    // console.log(wallet, accounts);
  }

  private async onNetworkChanged(wallet: string): Promise<void> {
    console.log(wallet);

    let walletService: any;

    // todo: make this dry
    if (wallet === "opwallet" && typeof window.opnet !== "undefined") {
      walletService = window.opnet;
    } else if (wallet === "unisat" && typeof window.unisat !== "undefined") {
      walletService = window.unisat;
    } else {
      return;
    }

    const chainId = await walletService.getChain();

    let network: string = "";
    let apiUrl: string = "";

    switch (chainId.enum) {
      case "BITCOIN_MAINNET":
        network = "mainnet";
        apiUrl = "https://api.opnet.org";
        break;
      case "BITCOIN_TESTNET":
        network = "testnet";
        apiUrl = "https://testnet.opnet.org";
        break;
      case "BITCOIN_REGTEST":
        network = "regtest";
        apiUrl = "https://regtest.opnet.org";
        break;
      case "FRACTAL_BITCOIN_MAINNET":
        network = "fractal_mainnet";
        apiUrl = "";
        break;
      case "FRACTAL_BITCOIN_TESTNET":
        network = "fractal_testnet";
        apiUrl = "https://fractal.opnet.org";
        break;
    }

    if (!apiUrl) {
      return;
    }

    this.provider = new JSONRpcProvider(apiUrl);
    this.utxoManager = new OPNetLimitedProvider(apiUrl);
    this.signer = new WalletSigner();
    await this.signer.initWithWallet(wallet);

    setRecoil(NetworkState, network!);
  }

  public isConnected(): boolean {
    return this.connected;
  }

  async connect(wallet: string, network: BitcoinNetwork) {
    let walletService: any;

    if (wallet === "opwallet" && typeof window.opnet !== "undefined") {
      walletService = window.opnet;
    } else if (wallet === "unisat" && typeof window.unisat !== "undefined") {
      walletService = window.unisat;
    } else {
      return;
    }

    let chainId;

    switch (network) {
      case "mainnet":
        chainId = "BITCOIN_MAINNET";
        break;
      case "testnet":
        chainId = "BITCOIN_TESTNET";
        break;
      case "regtest":
        chainId = "BITCOIN_REGTEST";
        break;
      case "fractal_mainnet":
        chainId = "FRACTAL_BITCOIN_MAINNET";
        break;
      case "fractal_testnet":
        chainId = "FRACTAL_BITCOIN_TESTNET";
        break;
    }

    const accounts = await walletService.requestAccounts();
    const currentChain = await walletService.getChain();

    console.log(accounts);

    if (currentChain.enum === chainId) {
      await this.onNetworkChanged(wallet);
    } else {
      await walletService.switchChain(chainId);
    }

    this.connected = true;
    this.wallet = wallet;

    setRecoil(WalletConnectState, this.connected);
  }

  async switchNetwork(network: string) {
    if (!this.connected || !this.wallet) {
      return;
    }
    await this.connect(this.wallet, network as BitcoinNetwork);
  }

  async call(
    contractAddress: string,
    abi: BitcoinInterfaceAbi,
    functionName: string,
    args: any[],
    sender?: string
  ) {
    const contractInterface = BitcoinInterface.from(abi);

    const functionAbi = contractInterface.abi.find(
      (item) =>
        item.name === functionName && item.type?.toLowerCase() === "function"
    ) as FunctionBaseData;

    if (!functionAbi) {
      throw new Error(`Function ${functionName} not found in ABI`);
    }

    const data = this.encodeFunctionData(functionAbi, args);
    const buffer = Buffer.from(data.getBuffer());
    const response = await this.provider?.call(contractAddress, buffer, sender);

    if (!response || "error" in response) {
      return response;
    }

    const decoded: DecodedOutput = functionAbi.outputs
      ? this.decodeOutput(functionAbi.outputs, response.result)
      : { values: [], obj: {} };

    response.setDecoded(decoded);
    response.setCalldata(buffer);

    return response;
  }

  async signInteraction(
    contractAddress: string,
    abi: BitcoinInterfaceAbi,
    functionName: string,
    args: any[]
  ) {
    if (!this.provider || !this.utxoManager || !this.signer) {
      return;
    }

    const sender = this.signer.p2tr;
    const call: any = await this.call(
      contractAddress,
      abi,
      functionName,
      args,
      sender
    );

    console.log(call);

    if (call.error) {
      return call;
    }

    // submits tx to network
    // todo: check what the opnet wallet uses for these params
    const utxoSetting: FetchUTXOParamsMultiAddress = {
      addresses: this.signer.addresses,
      minAmount: 20_000n,
      requestedAmount: BigInt(call.estimatedGas) / 1_000_000n,
      optimized: true,
    };

    // funding tx & interaction tx

    const utxos = await this.utxoManager.fetchUTXOMultiAddr(utxoSetting);
    if (!utxos || !utxos.length) {
      console.log("Insufficient funds.");
      return;
    }

    const interactionParameters: InteractionParametersWithoutSigner = {
      from: this.signer.p2tr,
      to: contractAddress,
      utxos: utxos,
      network: "regtest" as any,
      feeRate: 100, //interactionParameters.feeRate,
      priorityFee: BigInt(330n), // interactionParameters.priorityFee || 330n
      calldata: Buffer.from(call.calldata as unknown as string, "hex"),
    };

    let broadcastedTxs: [
      BroadcastedTransaction,
      BroadcastedTransaction,
      UTXO[],
    ];

    if (typeof window.unisat?.web3 !== "undefined") {
      const finalTx = await this.transactionFactory.signInteraction({
        ...interactionParameters,
        network: this.signer.network,
        signer: this.signer,
      });

      if (!finalTx) {
        console.log("Transaction failed.");
        return;
      }

      const broadcastTxA = await this.provider.sendRawTransaction(
        finalTx[0],
        false
      );
      if (!broadcastTxA.result) {
        console.log("Transaction failed.");
        return;
      }

      const broadcastTxB = await this.provider.sendRawTransaction(
        finalTx[1],
        false
      );
      if (!broadcastTxB.result) {
        console.log("Transaction failed.");
        return;
      }

      broadcastedTxs = [broadcastTxA, broadcastTxB, []];
    } else if (typeof window.opnet !== "undefined") {
      broadcastedTxs = await (window.opnet as any).signAndBroadcastInteraction(
        interactionParameters
      );
    } else {
      return;
    }

    const broadcastTxA = broadcastedTxs[0];
    const broadcastTxB = broadcastedTxs[1];
  }

  private encodeFunctionData(
    element: FunctionBaseData,
    args: unknown[]
  ): BinaryWriter {
    const writer = new BinaryWriter();
    const selector = Number("0x" + this.abiCoder.encodeSelector(element.name));
    writer.writeSelector(selector);

    if (args.length !== (element.inputs?.length ?? 0)) {
      throw new Error("Invalid number of arguments provided");
    }

    if (!element.inputs || (element.inputs && element.inputs.length === 0)) {
      return writer;
    }

    for (let i = 0; i < element.inputs.length; i++) {
      this.encodeInput(writer, element.inputs[i], args[i]);
    }

    return writer;
  }

  private encodeInput(
    writer: BinaryWriter,
    abi: BitcoinAbiValue,
    value: unknown
  ): void {
    const type = abi.type;
    const name = abi.name;

    switch (type) {
      case ABIDataTypes.UINT256: {
        if (typeof value !== "bigint") {
          value = BigInt(`${value}`);
          //throw new Error(`Expected value to be of type bigint (${name})`);
        }
        writer.writeU256(value as bigint);
        break;
      }
      case ABIDataTypes.BOOL: {
        if (typeof value !== "boolean") {
          throw new Error(`Expected value to be of type boolean (${name})`);
        }
        writer.writeBoolean(value);
        break;
      }
      case ABIDataTypes.STRING: {
        if (typeof value !== "string") {
          throw new Error(`Expected value to be of type string (${name})`);
        }
        writer.writeStringWithLength(value);
        break;
      }
      case ABIDataTypes.ADDRESS: {
        const address = value as BitcoinAddressLike;
        writer.writeAddress(address.toString());
        break;
      }
      case ABIDataTypes.TUPLE: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeTuple(value as bigint[]);
        break;
      }
      case ABIDataTypes.UINT8: {
        if (typeof value !== "number") {
          throw new Error(`Expected value to be of type number (${name})`);
        }
        writer.writeU8(value);
        break;
      }
      case ABIDataTypes.UINT16: {
        if (typeof value !== "number") {
          throw new Error(`Expected value to be of type number (${name})`);
        }
        writer.writeU16(value);
        break;
      }
      case ABIDataTypes.UINT32: {
        if (typeof value !== "number") {
          throw new Error(`Expected value to be of type number (${name})`);
        }
        writer.writeU32(value);
        break;
      }
      case ABIDataTypes.BYTES32: {
        if (!(value instanceof Uint8Array)) {
          throw new Error(`Expected value to be of type Uint8Array (${name})`);
        }
        writer.writeBytes(value);
        break;
      }
      case ABIDataTypes.ADDRESS_UINT256_TUPLE: {
        if (!(value instanceof Map)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeAddressValueTupleMap(value as Map<Address, bigint>);
        break;
      }
      case ABIDataTypes.BYTES: {
        if (!(value instanceof Uint8Array)) {
          throw new Error(`Expected value to be of type Uint8Array (${name})`);
        }

        writer.writeBytesWithLength(value);
        break;
      }
      case ABIDataTypes.UINT64: {
        if (typeof value !== "bigint") {
          throw new Error(`Expected value to be of type bigint (${name})`);
        }

        writer.writeU64(value);
        break;
      }
      case ABIDataTypes.ARRAY_OF_ADDRESSES: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeAddressArray(value as Address[]);
        break;
      }
      case ABIDataTypes.ARRAY_OF_UINT256: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeU256Array(value as bigint[]);
        break;
      }
      case ABIDataTypes.ARRAY_OF_UINT32: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeU32Array(value as number[]);
        break;
      }

      case ABIDataTypes.ARRAY_OF_STRING: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeStringArray(value as string[]);
        break;
      }

      case ABIDataTypes.ARRAY_OF_BYTES: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeBytesArray(value as Uint8Array[]);
        break;
      }

      case ABIDataTypes.ARRAY_OF_UINT64: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeU64Array(value as bigint[]);
        break;
      }

      case ABIDataTypes.ARRAY_OF_UINT8: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeU8Array(value as number[]);
        break;
      }

      case ABIDataTypes.ARRAY_OF_UINT16: {
        if (!(value instanceof Array)) {
          throw new Error(`Expected value to be of type Array (${name})`);
        }

        writer.writeU16Array(value as number[]);
        break;
      }

      default: {
        throw new Error(`Unsupported type: ${type} (${name})`);
      }
    }
  }

  private decodeOutput(
    abi: BitcoinAbiValue[],
    reader: BinaryReader
  ): DecodedOutput {
    const result: Array<DecodedCallResult> = [];
    const obj: ContractDecodedObjectResult = {};

    for (let i = 0; i < abi.length; i++) {
      const type = abi[i].type;
      const name = abi[i].name;

      let decodedResult: DecodedCallResult;
      switch (type) {
        case ABIDataTypes.UINT256:
          decodedResult = reader.readU256();
          break;
        case ABIDataTypes.BOOL:
          decodedResult = reader.readBoolean();
          break;
        case ABIDataTypes.STRING:
          decodedResult = reader.readStringWithLength();
          break;
        case ABIDataTypes.ADDRESS:
          decodedResult = reader.readAddress();
          break;
        case ABIDataTypes.TUPLE:
          decodedResult = reader.readTuple();
          break;
        case ABIDataTypes.UINT8:
          decodedResult = reader.readU8();
          break;
        case ABIDataTypes.UINT16:
          decodedResult = reader.readU16();
          break;
        case ABIDataTypes.UINT32:
          decodedResult = reader.readU32();
          break;
        case ABIDataTypes.BYTES32:
          decodedResult = reader.readBytes(32);
          break;
        case ABIDataTypes.ADDRESS_UINT256_TUPLE:
          decodedResult = reader.readAddressValueTuple();
          break;
        case ABIDataTypes.BYTES: {
          decodedResult = reader.readBytesWithLength();
          break;
        }
        case ABIDataTypes.UINT64: {
          decodedResult = reader.readU64();
          break;
        }
        case ABIDataTypes.ARRAY_OF_ADDRESSES: {
          decodedResult = reader.readAddressArray();
          break;
        }
        case ABIDataTypes.ARRAY_OF_UINT256: {
          decodedResult = reader.readU256Array();
          break;
        }
        case ABIDataTypes.ARRAY_OF_UINT32: {
          decodedResult = reader.readU32Array();
          break;
        }
        case ABIDataTypes.ARRAY_OF_STRING: {
          decodedResult = reader.readStringArray();
          break;
        }
        case ABIDataTypes.ARRAY_OF_BYTES: {
          decodedResult = reader.readBytesArray();
          break;
        }
        case ABIDataTypes.ARRAY_OF_UINT64: {
          decodedResult = reader.readU64Array();
          break;
        }
        case ABIDataTypes.ARRAY_OF_UINT8: {
          decodedResult = reader.readU8Array();
          break;
        }
        case ABIDataTypes.ARRAY_OF_UINT16: {
          decodedResult = reader.readU16Array();
          break;
        }
        default:
          throw new Error(`Unsupported type: ${type} (${name})`);
      }

      result.push(decodedResult);
      obj[name] = decodedResult;
    }

    return {
      values: result,
      obj: obj,
    };
  }
}
