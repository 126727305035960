import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";

export interface SearchProps {
  id: string;
}

export function useSearch(props: SearchProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);

  const { data, loading, error } = useApi(
    async (network: string, params: any) => {
      if ((params.length ?? 0) === 0) {
        return [];
      }
      return await opnetApi.getSearchList.bind(opnetApi)(network, params);
    },
    [network, props.id]
  );

  return {
    results: data ?? [],
    loading,
    error,
  };
}
