export function formatCountPlural(
  count: number,
  singular: string,
  plural: string
) {
  const displayCount = Number(count).toLocaleString("en-US");
  if (count > 1) {
    return `${displayCount} ${plural}`;
  }
  return `${displayCount} ${singular}`;
}
