import { useCallback, useEffect, useRef, useState } from "react";
import {
  FunctionSquare,
  Plus,
  Trash,
  Command,
  Verified,
  Wallet,
} from "lucide-react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ABIDataTypes } from "opnet";

import OpnetApiClient from "@/services/OpnetApiClient";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { truncateInMiddle } from "@/lib";
import { WalletConnect } from "@/services/WalletConnect";
import {
  NetworkState,
  WalletConnectState,
  ShowWalletConnectState,
} from "@/state";

const number_types = [
  ABIDataTypes.UINT8,
  ABIDataTypes.UINT16,
  ABIDataTypes.UINT32,
  ABIDataTypes.UINT64,
  ABIDataTypes.UINT256,
];

const array_types = {
  [ABIDataTypes.ARRAY_OF_BYTES]: ABIDataTypes.BYTES,
  // todo: swap order
  [ABIDataTypes.STRING]: ABIDataTypes.ARRAY_OF_STRING,
  [ABIDataTypes.ADDRESS]: ABIDataTypes.ARRAY_OF_ADDRESSES,
  [ABIDataTypes.UINT8]: ABIDataTypes.ARRAY_OF_UINT8,
  [ABIDataTypes.UINT16]: ABIDataTypes.ARRAY_OF_UINT16,
  [ABIDataTypes.UINT32]: ABIDataTypes.ARRAY_OF_UINT32,
  [ABIDataTypes.UINT64]: ABIDataTypes.ARRAY_OF_UINT64,
  [ABIDataTypes.UINT256]: ABIDataTypes.ARRAY_OF_UINT256,
};

const string_types = [ABIDataTypes.STRING, ABIDataTypes.ADDRESS];

const byte_types = [ABIDataTypes.BYTES, ABIDataTypes.BYTES32];

// todo: how do we handle tuple map type? todo..
const tuple_types = [ABIDataTypes.ADDRESS_UINT256_TUPLE];

function ArgumentValue({ type, setValue }: any) {
  if (type === ABIDataTypes.ADDRESS) {
    return (
      <Input
        type="text"
        placeholder="Address"
        onChange={(e) => setValue(e.target.value)}
      />
    );
  } else if (number_types.indexOf(type) > -1) {
    return (
      <Input
        type="number"
        placeholder={type}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  } else if (byte_types.indexOf(type) > -1) {
    return (
      <Textarea
        placeholder={`${type} (base64 encoded)`}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  } else if (type === ABIDataTypes.BOOL) {
    return <Switch onChange={(value) => setValue(value)} />;
  } else {
    return (
      <Input
        type="text"
        placeholder="String"
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }
}

function FunctionArgument({
  arg,
  list,
  setValue,
}: {
  arg: any;
  list: boolean;
  setValue: any;
}) {
  return (
    <div className="flex flex-col border rounded-xl p-4 gap-4">
      <div className="flex items-center justify-between">
        <div className="text-sm font-bold w-40 text-ellipsis">{arg.name}</div>
        {!list && arg.type !== "bytes" && (
          <ArgumentValue type={arg.type} setValue={setValue} />
        )}
      </div>
      {list ? (
        <div className="flex flex-row flex-1 items-center gap-4">
          <ArgumentValue type={arg.type} />
          <Button variant="outline" size="icon">
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        arg.type === "bytes" && (
          <ArgumentValue type={arg.type} setValue={setValue} />
        )
      )}
      {list && (
        <>
          <Separator />
          <Button variant="outline" className="uppercase" size="sm">
            <Plus className="mr-1 h-5 w-5" />
            <>Add Item</>
          </Button>
        </>
      )}
    </div>
  );
}

function FunctionResult({
  arg,
  list,
  value,
}: {
  arg: any;
  value: string;
  list: boolean;
}) {
  return (
    <div className="flex flex-col border rounded-xl p-4 gap-4">
      <div className="flex items-center justify-between">
        <div className="text-sm font-bold w-40 text-ellipsis">{arg.name}</div>
        {!list && arg.type !== "bytes" && (
          <div className="text-sm font-bold overflow-hidden text-wrap text-ellipsis">
            {value}
          </div>
        )}
      </div>
      {list ? (
        <div className="flex flex-row flex-1 items-center gap-4">
          <div className="text-sm font-bold overflow-hidden text-wrap text-ellipsis">
            {value}
          </div>
        </div>
      ) : (
        arg.type === "bytes" && (
          <div className="text-sm font-bold overflow-hidden text-wrap text-ellipsis">
            {value}
          </div>
        )
      )}
    </div>
  );
}

function EditableList({
  createItem,
  items = [],
  title,
  addText = "Add Item",
  addItem = () => {},
  removeItem = () => {},
}: any) {
  return (
    <div className="flex flex-col border rounded-xl p-4 gap-4">
      <div className="flex items-center justify-between">
        <div className="text-sm font-bold w-40 text-ellipsis">{title}</div>
      </div>
      {items.map((item: any, i: number) => (
        <div className="flex flex-row flex-1 items-center gap-4">
          {createItem(item, i)}
          <Button variant="outline" onClick={() => removeItem(i)}>
            <Trash className="h-4 w-4" />
          </Button>
        </div>
      ))}
      <Separator />
      <Button
        variant="outline"
        className="uppercase"
        size="sm"
        onClick={addItem}
      >
        <Plus className="mr-1 h-5 w-5" />
        <>Add Item</>
      </Button>
    </div>
  );
}

function ContractVerification({ address, close }: any) {
  const network = useRecoilValue(NetworkState);
  const abiRef = useRef<HTMLInputElement>(null);
  const sourceRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedABI, setUploadedABI] = useState<boolean>(false);
  const [uploadedSource, setUploadedSource] = useState<boolean>(false);
  const [entryFile, setEntryFile] = useState<string>("");
  const [ecmaScript, setEcmaScript] = useState<string>("ES2015");
  const [dependencies, setDependencies] = useState<any[]>([]);

  const enabled =
    ecmaScript &&
    dependencies?.length > 0 &&
    entryFile &&
    uploadedABI &&
    uploadedSource;

  const verify = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      const abiFile = abiRef.current?.files?.[0];
      const sourceFile = sourceRef.current?.files?.[0];
      const abiJSON = await abiFile?.text();
      if (!abiJSON || !sourceFile) {
        return;
      }
      const abi = JSON.parse(abiJSON);
      const res = await OpnetApiClient.instance.verifyContract(
        network,
        address,
        ecmaScript,
        dependencies,
        abi,
        entryFile,
        sourceFile
      );
      close();
    } catch (err) {
      setError("Failed to verify contract");
    }
    setLoading(false);
  }, [entryFile, address, ecmaScript, dependencies, network]);

  return (
    <div className="grid gap-4 py-4">
      {error && <div className="text-destructive">{error}</div>}
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="picture">ECMAScript Version</Label>
        <Select value={ecmaScript} onValueChange={setEcmaScript}>
          <SelectTrigger>
            <SelectValue placeholder="Select ECMAScript Version" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>ECMAScript Version</SelectLabel>
              <SelectItem value="ES2015">ES2015</SelectItem>
              <SelectItem value="ES2016">ES2016</SelectItem>
              <SelectItem value="ES2017">ES2017</SelectItem>
              <SelectItem value="ES2018">ES2018</SelectItem>
              <SelectItem value="ES2019">ES2019</SelectItem>
              <SelectItem value="ES2020">ES2020</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label>Entry File Path</Label>
        <Input
          type="text"
          placeholder="index.ts"
          onChange={(e) => setEntryFile(e.target.value)}
          value={entryFile}
        />
      </div>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label>ABI (.json)</Label>
        <Input
          type="file"
          accept=".json"
          ref={abiRef}
          onChange={() => setUploadedABI(true)}
        />
      </div>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label>Source Code (.zip)</Label>
        <Input
          type="file"
          accept=".zip"
          ref={sourceRef}
          onChange={() => setUploadedSource(true)}
        />
      </div>
      <EditableList
        createItem={(item: any, i: number) => (
          <>
            <Select
              value={item.value}
              onValueChange={(value: string) => {
                const deps = [...dependencies];
                deps[i].name = value;
                setDependencies(deps);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Package" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Package</SelectLabel>
                  <SelectItem value="@btc-vision/btc-runtime">
                    @btc-vision/btc-runtime
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <Input
              type="text"
              placeholder="Version"
              value={item.version}
              onChange={(e) => {
                const deps = [...dependencies];
                deps[i].version = e.target.value;
                setDependencies(deps);
              }}
            />
          </>
        )}
        removeItem={(i: number) => {
          const deps = [...dependencies];
          deps.splice(i, 1);
          setDependencies(deps);
        }}
        addItem={() => {
          setDependencies([...dependencies, { name: "", version: "" }]);
        }}
        items={dependencies}
        title="Dependencies"
      />
      <Button
        className="flex-1 bg-[#FF7900]"
        onClick={verify}
        loading={loading}
        disabled={!enabled}
      >
        <Verified className="mr-1 h-5 w-5" />
        Verify Contract
      </Button>
    </div>
  );
}

function ContractCall({ contract, close }: any) {
  const connected = useRecoilValue(WalletConnectState);
  const [walletOpen, setOpenWallet] = useRecoilState(ShowWalletConnectState);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [func, setFunction] = useState<number | undefined>();
  const [args, setArgs] = useState<any[]>([]);
  const [result, setResult] = useState<any>({});

  const abi = contract?.abi ?? [];
  const functions = abi.filter(
    (f: any) => f.type?.toLowerCase() === "function"
  );

  function setArgument(i: number, value: any) {
    args[i] = value;
    setArgs(args);
  }

  useEffect(() => {
    setArgs([]);
    setResult(undefined);
    setError(undefined);
  }, [func]);

  return (
    <div className="grid gap-4 py-4">
      {error && <div className="text-destructive">{error}</div>}
      <Select
        value={`${func}`}
        onValueChange={(value) => setFunction(parseInt(value))}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select a function" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Functions</SelectLabel>
            {functions.map(({ name, payable = false }: any, i: number) => (
              <SelectItem value={`${i}`} key={i}>
                {name} ({payable ? "Write" : "Read"})
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {func !== undefined &&
        (functions[func]?.inputs ?? []).map((arg: any, i: number) => (
          <FunctionArgument
            arg={arg}
            list={false}
            key={`${func}_${i}`}
            setValue={(value: any) => {
              setArgument(i, value);
            }}
          />
        ))}
      <Button
        className="flex-1 bg-[#FF7900]"
        onClick={async () => {
          if (typeof func === "undefined") {
            return;
          }
          try {
            setError(undefined);
            setResult(undefined);
            setLoading(true);

            if (args.length < functions[func].inputs.length) {
              throw new Error("Missing arguments");
            }

            if (!connected) {
              setOpenWallet(true);
              return;
            }

            let call: any;

            if (functions[func].payable) {
              call = await WalletConnect.instance.signInteraction(
                contract?.address,
                abi,
                functions[func].name,
                args
              );
            } else {
              call = await WalletConnect.instance.call(
                contract?.address,
                abi,
                functions[func].name,
                args
              );
            }

            if (call?.error) {
              console.log(call.error);
              throw new Error(call.error?.toString());
            } else if (call?.properties) {
              console.log(call?.properties);
              setResult(call.properties ?? {});
            }
          } catch (err: any) {
            setError(err?.message ?? err?.toString() ?? "An error occurred");
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
      >
        {connected ? (
          <Command className="mr-1 h-5 w-5" />
        ) : (
          <Wallet className="mr-1 h-5 w-5" />
        )}
        {connected ? "Execute" : "Connect Wallet"}
      </Button>
      {func !== undefined &&
        typeof result !== "undefined" &&
        (functions[func]?.outputs ?? []).map((arg: any, i: number) => (
          <FunctionResult
            arg={arg}
            list={false}
            value={String(result[arg.name])}
            key={`${func}_${i}_result`}
          />
        ))}
    </div>
  );
}

export function ContractInteraction({ contract }: any) {
  const [open, setOpen] = useState<boolean>(false);

  function close() {
    setOpen(false);
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        {contract?.verified ? (
          <Button variant="outline" className="rounded-lg text-black" size="sm">
            <FunctionSquare className="mr-1 h-5 w-5" />
            Interact
          </Button>
        ) : (
          <Button variant="outline" className="rounded-lg text-black" size="sm">
            <Verified className="mr-1 h-5 w-5" />
            Verify
          </Button>
        )}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          {contract?.verified ? (
            <SheetTitle>
              Interact with{" "}
              {contract?.op20Metadata?.name ??
                truncateInMiddle(contract?.address)}
            </SheetTitle>
          ) : (
            <SheetTitle>
              Verify{" "}
              {contract?.op20Metadata?.name ??
                truncateInMiddle(contract?.address)}
            </SheetTitle>
          )}
          <SheetDescription>
            {contract?.verified
              ? "Select a function and fill in the arguments"
              : "Make sure dependency versions exactly match or verification will fail. NOTE: This feature is still experimental. Documentation coming soon."}
          </SheetDescription>
        </SheetHeader>
        {contract?.verified ? (
          <ContractCall contract={contract} close={close} />
        ) : (
          <ContractVerification address={contract?.address} close={close} />
        )}
      </SheetContent>
    </Sheet>
  );
}
