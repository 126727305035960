import { useParams } from "react-router-dom";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useBlock } from "@/hooks/useBlock";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { CopyableText } from "@/components/CopyableText";
import { formatDateToNow, satsToBTC, gasToSats } from "@/lib";
import { TransactionList } from "@/components/TransactionList";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export function BlockPage() {
  const { id } = useParams();
  const { block, loading } = useBlock({
    heightOrHash: id ?? "",
  });

  if (loading) {
    return <div />;
  }

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        title={`Block #${Number(block?.height ?? 0).toLocaleString("en-US")}`}
        accessory={block && formatDateToNow(block?.time)}
      />
      <div className="flex flex-col grow gap-4 justify-center">
        <div className="flex flex-col lg:flex-row gap-4">
          <Card className="flex flex-col overflow-hidden grow lg:w-2/4">
            <CardHeader>
              <CardTitle>Summary</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="HEIGHT"
                value={
                  <CopyableText
                    text={block?.height}
                    displayText={`#${Number(block?.height ?? 0).toLocaleString("en-US")}`}
                  />
                }
              />
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText text={block?.hash} displayText={block?.hash} />
                }
              />
              <InfoCardCell
                title="NUMBER OF TXS"
                value={(block?.txCount ?? 0)?.toLocaleString("en-US")}
              />
              <InfoCardCell
                title="MINER REWARD TX"
                value={
                  <CopyableText
                    text={block?.minerRewardTransactionId}
                    displayText={block?.minerRewardTransactionId}
                  />
                }
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col overflow-hidden grow lg:w-1/4">
            <CardHeader>
              <CardTitle>Stats</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="TOTAL BURNED"
                value={`${satsToBTC(
                  BigInt(block?.burnedBitcoin ?? 0)
                )?.toLocaleString("en-US", {
                  maximumFractionDigits: 8,
                })} BTC`}
              />
              <InfoCardCell
                title="TOTAL GAS USED"
                value={`${satsToBTC(
                  gasToSats(BigInt(block?.gasUsed ?? 0))
                )?.toLocaleString("en-US", {
                  maximumFractionDigits: 8,
                })} BTC`}
              />
              <InfoCardCell
                title="TOTAL CONTRACT CALLS"
                value={`${block?.contractCallCount?.toLocaleString("en-US")}`}
              />
              <InfoCardCell
                title="TOTAL CONTRACT DEPLOYS"
                value={`${block?.contractDeployCount?.toLocaleString("en-US")}`}
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col overflow-hidden grow lg:w-1/4">
            <CardHeader>
              <CardTitle>State</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="CONFIRMATIONS"
                value={`${block?.confirmations?.toLocaleString("en-US")}`}
              />
              <InfoCardCell
                title="VALIDATORS"
                value={`${block?.validators?.total?.toLocaleString("en-US")}`}
              />
              <InfoCardCell
                title="TRUSTED VALIDATORS"
                value={`${block?.validators?.trusted?.toLocaleString("en-US")}`}
              />
            </CardContent>
          </Card>
        </div>
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent>
            <Accordion type="single" collapsible>
              <AccordionItem value="advanced">
                <AccordionTrigger>Advanced Info</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2 grow">
                  <InfoCardCell
                    title="CHECKSUM ROOT"
                    value={
                      <CopyableText
                        text={block?.checksumRoot}
                        displayText={block?.checksumRoot}
                      />
                    }
                  />
                  <InfoCardCell
                    title="PREV BLOCK CHECKSUM"
                    value={
                      <CopyableText
                        text={block?.previousBlockChecksum}
                        displayText={block?.previousBlockChecksum}
                      />
                    }
                  />
                  <InfoCardCell
                    title="PREV BLOCK HASH"
                    value={
                      <CopyableText
                        text={block?.previousBlockHash}
                        displayText={block?.previousBlockHash}
                      />
                    }
                  />
                  <InfoCardCell
                    title="MERKLE ROOT"
                    value={
                      <CopyableText
                        text={block?.merkleRoot}
                        displayText={block?.merkleRoot}
                      />
                    }
                  />
                  <InfoCardCell
                    title="STORAGE ROOT"
                    value={
                      <CopyableText
                        text={block?.storageRoot}
                        displayText={block?.storageRoot}
                      />
                    }
                  />
                  <InfoCardCell
                    title="RECEIPT ROOT"
                    value={
                      <CopyableText
                        text={block?.receiptRoot}
                        displayText={block?.receiptRoot}
                      />
                    }
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent className="p-0">
            <TransactionList
              compact={false}
              paginate={true}
              blockHash={block?.height}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
