import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";

export interface GetSmartContractProps {
  id: string;
}

export function useSmartContract(props: GetSmartContractProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);

  const { data, loading, error } = useApi(
    opnetApi.getContractByAddress.bind(opnetApi),
    [network, props.id]
  );

  return {
    contract: data,
    loading,
    error,
  };
}
