import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

export type DataPreviewCardProps = {
  title: string;
  actionTitle: string;
  href: string;
};

export function DataPreviewCard({
  title,
  children,
  actionTitle,
  href,
}: PropsWithChildren<DataPreviewCardProps>) {
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="p-0">{children}</CardContent>
      <CardFooter className="p-0">
        <Button className="w-full rounded-none" asChild>
          <Link to={href}>
            {actionTitle} <ArrowRight className="ml-1 h-5 w-5" />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
