import { useState, useEffect } from "react";

export interface ApiRequestState {
  loading: boolean;
  error: any;
}
export function useApi(
  fn: Function,
  params: any[] = []
): ApiRequestState & { data: any } {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setData(null);
        setLoading(true);
        const result = await fn(...params);
        setData(result);
      } catch (error) {
        console.log(error);
        setError(error);
      }
      setLoading(false);
    }
    fetchData();
  }, [JSON.stringify(params)]);

  return { data, loading, error };
}
