import { DollarSign } from "lucide-react";
import { Link } from "react-router-dom";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { truncateInMiddle, formatNumber, satsToBTC } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";

export type TransferRowProps = {
  transfer: any;
  address?: string;
  compact?: boolean;
  className?: string;
};

export function TransferRow({
  transfer,
  compact = false,
  className,
}: TransferRowProps) {
  const amount = satsToBTC(transfer?.inputValue ?? 0);
  // todo: take address into account for calc
  /*
  <Button
    variant="link"
    className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
    asChild
  >
    {transaction.outputs?.length === 1 ? (
      <Link to={`/accounts/${transaction?.outputAddresses[0]}`}>
        {truncateInMiddle(transaction?.outputAddresses[0] ?? "", 10)}
      </Link>
    ) : (
      <>{transaction.outputs?.length} Outputs</>
    )}
  </Button> */
  return (
    <CommonRow
      icon={DollarSign}
      title={
        <div className="flex flex-row items-center gap-1">
          {`Received ${formatNumber(amount)} BTC`}
          <div className="text-xs font-medium text-muted-foreground">
            <BTCPriceConversion amount={amount} />
          </div>
        </div>
      }
      subtitle={
        <Button variant="link" size="none">
          <Link to={`/accounts/${transfer?.inputAddresses[0]}`}>
            From:{" "}
            {transfer?.inputAddresses[0] &&
              truncateInMiddle(transfer?.inputAddresses[0], 10)}
          </Link>
          {transfer.inputs?.length === 1 ? (
            <Link to={`/accounts/${transfer?.outputAddresses[0]}`}>
              {transfer?.outputAddresses[0] &&
                truncateInMiddle(transfer?.outputAddresses[0] ?? "", 10)}
            </Link>
          ) : (
            <>{transfer.inputs?.length} Inputs</>
          )}
        </Button>
      }
      className={className}
      href={`/transactions/${transfer?.id}`}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={transfer?.id}
            displayText={transfer?.id && truncateInMiddle(transfer?.id, 10)}
          />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
