import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";

export interface GetAccountProps {
  address: string;
}

export function useAccount(props: GetAccountProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);

  const { data, loading, error } = useApi(
    opnetApi.getWalletById.bind(opnetApi),
    [network, props.address]
  );

  return {
    account: data,
    loading,
    error,
  };
}
