import { useMemo } from "react";
import {
  Coins,
  Lock,
  ArrowRightLeft,
  Activity,
  ArrowRight,
  ScrollText,
  LucideIcon,
  ArrowBigRight,
  Flame,
  Check,
  Group,
  Unlock,
  BadgeDollarSign,
} from "lucide-react";
import { Transition } from "@headlessui/react";

import { CopyableText } from "@/components/CopyableText";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { TableRow } from "@/components/ui/table";
import { formatDateToNow, truncateInMiddle, cn, base64ToHex } from "@/lib";
import { Button } from "@/components/ui/button";

export type EventRowProps = {
  event: {
    eventType: string;
    properties: Record<string, string>;
    eventData: string;
  };
  compact?: boolean;
  className?: string;
};

export function EventRow({ event, compact = false, className }: EventRowProps) {
  const Icon: LucideIcon = useMemo(() => {
    switch (event.eventType) {
      case "Mint":
        return Coins;
      case "Transfer":
        return ArrowBigRight;
      case "Burn":
        return Flame;
      case "Approve":
        return Check;
      case "PoolCreated":
        return Group;
      case "PoolBurn":
        return Flame;
      case "PoolMint":
        return Coins;
      case "Swap":
        return ArrowRightLeft;
      case "Sync":
        return ArrowRightLeft;
      case "Stake":
        return Lock;
      case "Unstake":
        return Unlock;
      case "Claim":
        return Activity;
      case "WithdrawalRequest":
        return BadgeDollarSign;
      default:
        return Activity;
    }
  }, [event.eventType]);
  const hex = useMemo(() => {
    return base64ToHex(event.eventData);
  }, [event.eventData]);
  return (
    <Transition
      show={true}
      appear={true}
      className={cn(["bg-white text-sm", className])}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="px-5 pb-5 flex flex-col items-start w-full">
        <div className="flex flex-row items-center gap-2 w-full">
          <div className="p-1 bg-black rounded-md inline-block">
            <Icon color="#FFF" className="h-4 w-4" />
          </div>
          <div className="font-bold text-sm p-0 h-auto" key="title">
            {event.eventType}
          </div>
        </div>
        <div className="py-2 flex flex-row gap-2 text-xs w-full">
          <div className="flex flex-col gap-1 justify-start items-start">
            {Object.entries(event.properties).map(([key, value]) => (
              <div className="py-1 px-2 rounded-md border font-semibold text-xs">
                {key}
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-1 justify-center bg-[#333CCFF] flex-grow">
            {Object.entries(event.properties).map(([key, value]) => (
              <div className="h-[26px] text-sm font-medium flex items-center">
                <CopyableText text={value} displayText={value} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1 text-xs border rounded-lg bg-muted text-muted-foreground p-4 w-full">
          <div className="font-semibold uppercase">EVENT DATA</div>
          <CopyableText text={hex} displayText={hex} />
        </div>
      </div>
    </Transition>
  );
}
