import { usePagination } from "@/hooks";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody } from "@/components/ui/table";
import { EventRow } from "@/components/EventRow";
import { DataTablePagination } from "@/components/DataTablePagination";

export function TransactionEventsCard({ transaction }: { transaction: any }) {
  const { page, changePageSize, prevPage, nextPage, pageSize, goToPage } =
    usePagination({
      initialPageSize: 5,
    });

  const events = transaction?.events ?? [];
  const total = events.length;
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  const pageEvents = events.slice(start, end);
  const hasNextPage = events.length > end + 1;

  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>Events</CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {pageEvents.map((event: any, i: number) => (
          <EventRow event={event} key={page + i} />
        ))}

        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={false}
            total={total}
            page={page}
            pageSize={5}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      </CardContent>
    </Card>
  );
}
