import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface BlockListProps extends PaginationOptions {
  heightOrHash?: string;
}

export function useBlocks(props?: BlockListProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const apiFn = opnetApi.getBlockList;

  const { data, loading, error } = useApi(apiFn.bind(opnetApi), [
    network,
    {
      limit: pageSize,
      cursor: page - 1,
      height_or_hash: props?.heightOrHash,
    },
  ]);

  return {
    blocks: data?.results || [],
    total: data?.total ?? 0,
    hasNextPage: data?.hasNextPage ?? true,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
