import axios, { AxiosInstance } from "axios";

const COINGECKO_BASE_API_URL = "https://pro-api.coingecko.com/api/v3";
const COINGECKO_API_KEY = "CG-mQ8MnR6DHjBRxyqUSWjJKKFf";

export class TokenPriceFetcher {
  public static instance = new TokenPriceFetcher();

  private coinGeckoClient: AxiosInstance;
  private pendingRequests: Record<string, Promise<any>> = {};
  private historicalPriceCache: Record<string, any> = {};

  private constructor() {
    this.coinGeckoClient = axios.create({
      baseURL: COINGECKO_BASE_API_URL,
      timeout: 10000,
      params: { x_cg_pro_api_key: COINGECKO_API_KEY },
    });
  }

  async fetchPrice(date?: string | number) {
    const key = date ? this.createDayString(date) : "live";
    return this.checkCacheOrFetch(key, date);
  }

  private async checkCacheOrFetch(key: string, date?: string | number) {
    if (this.historicalPriceCache[key]) {
      return this.historicalPriceCache[key];
    }

    const url = date ? "/coins/bitcoin/history" : "/simple/price";
    const params = date
      ? { date: key }
      : {
          ids: "bitcoin",
          vs_currencies: "usd",
          include_market_cap: true,
          include_24hr_vol: true,
          include_24hr_change: true,
          include_last_updated_at: true,
        };

    return this.fetchAndCache(key, url, params);
  }

  private async fetchAndCache(key: string, url: string, params: any) {
    if (!this.pendingRequests[key]) {
      this.pendingRequests[key] = this.coinGeckoClient.get(url, { params });
    }

    try {
      const resp = await this.pendingRequests[key];
      this.historicalPriceCache[key] =
        resp.data?.bitcoin ?? resp.data?.market_data?.current_price;
      return this.historicalPriceCache[key];
    } catch (error) {
      throw error;
    } finally {
      delete this.pendingRequests[key];
    }
  }

  private createDayString(date: string | number): string {
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  }
}
