import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useAccountBalances } from "@/hooks";
import { FTHoldingRow } from "@/components/FTHoldingRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type TokenBalancesListProps = {
  address: string;
  paginate?: boolean;
  compact?: boolean;
};

export function TokenBalancesList({
  address,
  paginate,
  compact,
}: TokenBalancesListProps) {
  const {
    balances,
    total,
    loading,
    goToPage,
    page,
    pageSize,
    changePageSize,
    prevPage,
    nextPage,
    hasNextPage,
  } = useAccountBalances({ address });
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Token Info</TableHead>
            <TableHead>Balance</TableHead>
            <TableHead>Contract ID</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading
            ? Array.from({ length: 10 }).map((_, i) => (
                <SkeletonRow
                  icon={true}
                  title={true}
                  subtitle={true}
                  date={false}
                  otherCols={1}
                  key={i}
                />
              ))
            : null}
          {!loading &&
            balances.map((balance: any) => (
              <FTHoldingRow
                balance={balance}
                compact={compact}
                key={balance?.contractAddress}
              />
            ))}
        </TableBody>
      </Table>
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            total={total}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
            hasNextPage={hasNextPage}
          />
        </div>
      )}
    </div>
  );
}
