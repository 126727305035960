import { useCoinGeckoPrice } from "@/hooks/useCoinGeckoPrice";

export type BTCPriceConversionProps = {
  amount: number;
  date?: string | number;
  className?: string;
};

export function BTCPriceConversion({
  amount,
  date,
  className,
}: BTCPriceConversionProps) {
  const { data } = useCoinGeckoPrice({ date });
  return (
    <span className={className}>
      (${Number(data?.usd * amount)?.toLocaleString("en-US")} USD)
    </span>
  );
}
