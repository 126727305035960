import { forwardRef, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const AnnouncementBar = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "bg-[#FF7900] h-10 flex justify-center items-center",
      className
    )}
    {...props}
  />
));

AnnouncementBar.displayName = "AnnouncementBar";

export { AnnouncementBar };
